<template>
    <div class="search_box" v-bind:style="{ height: Height + 'px' }">
        <!-- 文献查找 -->
        <el-container>
            <el-main v-bind:style="{ height: Height + 'px' }" style="background: #f9f0f4;box-sizing: border-box;">
                <citationTrace v-if="keys == '1'"></citationTrace>
                <citationTraceEn v-else-if="keys == '2'"></citationTraceEn>
                <citationTraceList v-else-if="keys == '3'"></citationTraceList>
                <citationTracePersonal v-else="keys == '4'"></citationTracePersonal>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import citationTrace from './citationTrace.vue'
import citationTraceEn from './citationTraceEn.vue'
import citationTraceList from './citationTraceList.vue'
import citationTracePersonal from './citationTracePersonal.vue'
export default {
    data() {
        return {
            keys: "",
            Height: 0,
        }
    },
    components: {
        citationTrace,
        citationTraceEn,
        citationTraceList,
        citationTracePersonal
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 80;
        };
        // 监听当前页面 显示状态
        window.addEventListener("visibilitychange", this.hanldeVisiblityChange);

        // 当页面被销毁时 移除监听
        this.$on("hook:beforeDestroy", () => {
            // console.info("ws 我被销毁了, 移除监听>>>");
            window.removeEventListener(
                "visibilitychange",
                this.hanldeVisiblityChange
            );
        });
    },
    created() {
        if (this.$route.query.webPage) {
            this.keys = this.$route.query.webPage.toString();
        }
    },
    // 监听路由变化
    watch: {
        $route(to, from) {
            if (to.query.webPage) {
                this.keys = to.query.webPage.toString();
                console.log(this.keys);
            }
        },
    },
}
</script>

<style lang="less" scoped>
.search_box{
    box-sizing: border-box;
    overflow: auto;
    
    ::v-deep .el-main {
        min-width: 1130px;
        height: 100%;
        background-color: #e9eef3;
        color: #333;
    }
}
</style>